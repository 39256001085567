import React from 'react';
import Image from 'next/image';

const Tree: React.FC = () => {
  return (
    <Image
      alt="Tree"
      src="/static/tree.svg"
      width="24px"
      height="24px"
      color="white"
      quality={100}
    />
  );
};

export default Tree;
