import React, { forwardRef, Ref } from 'react';
import NextLink from 'next/link';
import { Box, Container, Flex, HStack, Link, Spacer } from '@chakra-ui/react';
import Tree from './Tree';

// const Footer: React.FC = () => {
const Footer = forwardRef((props, ref: Ref<HTMLDivElement>) => {
  return (
    <div ref={ref}>
      <Box paddingTop="4" backgroundColor="gray.700" paddingBottom="4">
        <Container maxW="container.xl">
          {/* <Center> */}
          <Flex>
            <Box></Box>
            <Spacer />
            <Box>
              <HStack>
                <Tree />
                <Box color="white">
                  CampWatch &copy;{` ${new Date().getFullYear()}`}
                </Box>
              </HStack>
            </Box>
            <Spacer />
            <Box color="white">
              <NextLink href="/contact" passHref>
                <Link color="white">Contact Us</Link>
              </NextLink>
            </Box>
          </Flex>
          {/* </Center> */}
          {/* <Flex>
            <Box w="48%">
              <Stack align="start">
                <FooterLink text="About" href="/about" />
                <FooterLink text="Frequently Asked Questions" href="/faq" />
                <FooterLink text="Pricing" href="/pricing" />
                <FooterLink text="Reviews" href="/reviews" />
              </Stack>
            </Box>
            <Spacer />
            <Box w="48%">
              <Stack align="start">
                <FooterLink text="Login" href="/auth/login" />
                <FooterLink text="Register" href="/auth/register" />
                <FooterLink text="Contact" href="/contact" />
              </Stack>
            </Box>
          </Flex>
          <Flex>
            <Spacer />
            <Box color="white">&copy;{` ${new Date().getFullYear()}`}</Box>
          </Flex>
          {/* <Center color={'white'} marginTop="4">
        </Center> */}
        </Container>
      </Box>
    </div>
  );
});

interface FooterLink {
  text: string;
  href: string;
}

const FooterLink: React.FC<FooterLink> = ({ text, href }) => {
  return (
    <NextLink href={href} passHref>
      <Link
        textAlign="center"
        fontFamily={'heading'}
        // color={'gray.800'}
        color={'white'}
        // href="/"
      >
        {text}
      </Link>
    </NextLink>
  );
};

Footer.displayName = 'Footer';

export default Footer;
