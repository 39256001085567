import React, { useRef } from 'react';
import { useSession } from 'next-auth/client';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useScreenHeight } from 'lib/hooks';
import { User } from 'types';
import NavBar from './NavBar';
import Footer from 'components/Footer';

interface LayoutProps {
  children: JSX.Element;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [session, loading] = useSession();

  const { height } = useScreenHeight();

  const navbarRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const navbarHeight = navbarRef.current?.clientHeight || 0;
  const footerHeight = footerRef.current?.clientHeight || 0;

  if (!!loading) {
    return (
      <Flex minH="100vh" align="center" justifyContent="center">
        <Spinner speed="0.65s" size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <NavBar ref={navbarRef} user={session?.user as User} />
      <Box bg="gray.50" minH={height - navbarHeight - footerHeight}>
        {children}
      </Box>
      <Footer ref={footerRef} />
    </>
  );
};

export default Layout;
