import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

interface ScreenWidth {
  isLarger: boolean;
  width: number;
}

// export const useScreen = ()

export const useScreenWidth = (maxWidth: number): ScreenWidth => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [isLarger] = useMediaQuery(`(min-width: ${maxWidth}px)`);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;

    setWindowDimensions({
      width,
      height,
    });

    function handleResize() {
      setWindowDimensions({
        width,
        height,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isLarger, width: isLarger ? maxWidth : windowDimensions.width };
};

interface ScreenHeight {
  height: number;
}

export const useScreenHeight = (): ScreenHeight => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;

    setWindowDimensions({
      width,
      height,
    });

    function handleResize() {
      setWindowDimensions({
        width,
        height,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { height: windowDimensions.height };
};
