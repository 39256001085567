import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Provider } from 'next-auth/client';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { CSSReset, ChakraProvider } from '@chakra-ui/react';
import Layout from 'components/Layout/Layout';

// const stripePromise = loadStripe(
//   'pk_test_51HQ1EyBAGNacBCKJtOIQPVqBJYFLC4k9Qz0P4znrBYlEc1KbgZGq1BNernPK8wQu3oPLqSmzYd7lxB1QBTJci4FX003vRRuWnI'
// );

function HeadSection() {
  return (
    <Head>
      <title>CampWatch</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta
        name="description"
        content="
          CampWatch is a service that finds campsites that have recently become available
          and will notify you immediately when a campsite you want becomes available.
        "
      />
    </Head>
  );
}

const MyApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <HeadSection />
      <ChakraProvider>
        <CSSReset />
        {/* <Elements stripe={stripePromise}> */}
        <Provider session={pageProps.session}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Provider>
        {/* </Elements> */}
      </ChakraProvider>
    </>
  );
};

export default MyApp;
