import React, { forwardRef, Ref } from 'react';
import NextLink from 'next/link';
// import { signOut } from 'next-auth/client';
import {
  Box,
  Link,
  Collapse,
  Text,
  Stack,
  // Button,
  Flex,
  IconButton,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import router from 'next/router';
import { User } from 'types';

interface INavbarProps {
  user?: User | null;
}

// Inspired by https://chakra-templates.dev/navigation/navbar
// const Navbar: React.FC<INavbarProps> = ({ user, }: INavbarProps) => {
const Navbar = forwardRef((props: INavbarProps, ref: Ref<HTMLDivElement>) => {
  const { user } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div ref={ref}>
      <Flex
        bg={'gray.800'}
        color={'gray.600'}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={'gray.900'}
        align={'center'}
      >
        {/* Hamburger Menu */}
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        {/* Logo */}
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <NextLink href="/" passHref>
            <Link
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              color={'white'}
            >
              CampWatch
            </Link>
          </NextLink>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav user={user} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={4}
          pl={2}
        >
          {/* {!user ? (
            <>
              <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                onClick={() => router.push('/auth/login')}
              >
                Sign In
              </Button>
              <Button
                // display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                fontWeight={600}
                color={'white'}
                bg={'pink.400'}
                onClick={() => router.push('/register')}
                _hover={{
                  bg: 'pink.300',
                }}
              >
                Sign Up
              </Button>
            </>
          ) : (
            <>
              <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                onClick={() => {
                  router.push('/');
                  signOut();
                }}
              >
                Sign Out
              </Button>
            </>
          )} */}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav user={user} />
      </Collapse>
    </div>
  );
});

const DesktopNav = ({ user }: INavbarProps) => {
  // const linkColor = useColorModeValue('gray.600', 'gray.200');
  // const linkHoverColor = useColorModeValue('gray.800', 'white');
  // const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const linkColor = 'gray.200';
  const linkHoverColor = 'white';
  // const popoverContentBgColor = 'gray.800';

  const NAV_ITEMS = user ? AUTHED_NAV_ITEMS : UNAUTHED_NAV_ITEMS;

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          {/* <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger> */}
          <NextLink href={navItem.href ?? '#'} passHref>
            <Link
              p={2}
              fontSize={'sm'}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              {navItem.label}
            </Link>
          </NextLink>
          {/* </PopoverTrigger> */}

          {/* {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover> */}
        </Box>
      ))}
    </Stack>
  );
};

// const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
//   return (
//     <Link
//       href={href}
//       role={'group'}
//       display={'block'}
//       p={2}
//       rounded={'md'}
//       // _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
//       _hover={{ bg: 'gray.900' }}
//     >
//       <Stack direction={'row'} align={'center'}>
//         <Box>
//           <Text
//             transition={'all .3s ease'}
//             _groupHover={{ color: 'pink.400' }}
//             fontWeight={500}
//           >
//             {label}
//           </Text>
//           <Text fontSize={'sm'}>{subLabel}</Text>
//         </Box>
//         <Flex
//           transition={'all .3s ease'}
//           transform={'translateX(-10px)'}
//           opacity={0}
//           _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
//           justify={'flex-end'}
//           align={'center'}
//           flex={1}
//         >
//           <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
//         </Flex>
//       </Stack>
//     </Link>
//   );
// };

const MobileNav = ({ user }: INavbarProps) => {
  const NAV_ITEMS = user ? AUTHED_NAV_ITEMS : UNAUTHED_NAV_ITEMS;
  return (
    <Stack
      // bg={useColorModeValue('white', 'gray.800')}
      bg="gray.800"
      p={4}
      display={{ md: 'none' }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <NextLink href={href ?? '#'}>
        <Flex
          py={2}
          as={Link}
          // href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text
            fontWeight={600}
            // color={useColorModeValue('gray.600', 'gray.200')}
            color={'gray.200'}
          >
            {label}
          </Text>
          {/* {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )} */}
        </Flex>
      </NextLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          // borderColor={useColorModeValue('gray.200', 'gray.700')}
          borderColor={'gray.700'}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const UNAUTHED_NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Create CampWatch',
  //   href: '/campwatches/create',
  // },
  {
    label: 'About',
    href: '/about',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
];
const AUTHED_NAV_ITEMS: Array<NavItem> = [
  {
    label: 'My CampWatches',
    href: '/campwatches/list',
  },
  {
    label: 'Create CampWatch',
    href: '/campwatches/create',
  },
  {
    label: 'Profile',
    href: '/profile',
  },
];

Navbar.displayName = 'Navbar';

export default Navbar;
